<template>
    <div class="metrics">
        <div class="py-6 title metrics-title">
            <span>{{ $t('metrics.title') }}</span>
        </div>
        <v-row>
            <v-col cols="4">
                <v-card
                    class="card-radius"
                    elevation="7"
                    outlined
                >
                    <v-card-title>
                        <v-icon class="metrics-icon" color="white">mdi-currency-eur</v-icon>
                        <span class="metrics-title pa-4">
                          {{ $t('metrics.revenues') }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-row class="metrics-background">
                            <v-col class="d-flex align-center" cols="6">
                                <span class="metrics-info-label">{{ getCurrentDate() }}</span>
                            </v-col>
                            <v-col align-self="end" cols="6">
                                <span class="metrics-info-value">{{ formatPrice(revenuesDay) }} €</span>
                            </v-col>
                        </v-row>
                        <v-row class="metrics-background mt-4">
                            <v-col class="pb-0" cols="12">
                                <span class="metrics-subtitle">{{ $t('metrics.revenues7Day') }}</span>
                            </v-col>
                            <v-col class="d-flex align-center" cols="6">
                                <span class="metrics-info-label">{{ getDate7Days() }}</span>
                            </v-col>
                            <v-col class="pt-0" cols="6">
                                <span class="metrics-info-value">{{ formatPrice(revenues7Day) }} €</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card
                    class="card-radius"
                    elevation="7"
                    outlined
                >
                    <v-card-title>
                        <v-icon class="metrics-icon" color="white">mdi-receipt</v-icon>
                        <span class="metrics-title pa-4">
                          {{ $t('metrics.orders') }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-row class="metrics-background">
                            <v-col class="d-flex align-center" cols="6">
                                <span class="metrics-info-label">{{ getCurrentDate() }}</span>
                            </v-col>
                            <v-col align-self="end" cols="6">
                                <span class="metrics-info-value">{{ ordersDay }}</span>
                            </v-col>
                        </v-row>
                        <v-row class="metrics-background mt-4">
                            <v-col class="pb-0" cols="12">
                                <span class="metrics-subtitle">{{ $t('metrics.orders7Day') }}</span>
                            </v-col>
                            <v-col class="d-flex align-center" cols="6">
                                <span class="metrics-info-label">{{ getDate7Days() }}</span>
                            </v-col>
                            <v-col class="pt-0" cols="6">
                                <span class="metrics-info-value">{{ orders7Day }}</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card
                    class="card-radius"
                    elevation="7"
                    outlined
                >
                    <v-card-title>
                        <v-icon class="metrics-icon" color="white">mdi-cart-outline</v-icon>
                        <span class="metrics-title pa-4">
                          {{ $t('metrics.baskets') }}
                        </span>
                    </v-card-title>
                    <v-card-text>
                        <v-row class="metrics-background">
                            <v-col class="d-flex align-center" cols="6">
                                <span class="metrics-info-label">{{ getCurrentDate() }}</span>
                            </v-col>
                            <v-col align-self="end" cols="6">
                                <span class="metrics-info-value">{{ basketsDay }}</span>
                            </v-col>
                        </v-row>
                        <v-row class="metrics-background mt-4">
                            <v-col class="pb-0" cols="12">
                                <span class="metrics-subtitle">{{ $t('metrics.baskets7Day') }}</span>
                            </v-col>
                            <v-col class="d-flex align-center" cols="6">
                                <span class="metrics-info-label">{{ getDate7Days() }}</span>
                            </v-col>
                            <v-col class="pt-0" cols="6">
                                <span class="metrics-info-value">{{ baskets7Day }}</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "Metrics",
    props: ['orders', 'orderOriginCode'],
    data: () => ({
        revenuesDay: 0,
        revenues7Day: 0,
        ordersDay: 0,
        orders7Day: 0,
        basketsDay: 0,
        baskets7Day: 0,
    }),
    methods: {
        calculateMetrics() {
            this.revenuesDay = 0
            this.revenues7Day = 0
            this.ordersDay = 0
            this.orders7Day = 0
            this.basketsDay = 0
            this.baskets7Day = 0

            this.orders.forEach(order => {
                if (order.orderOrigin === this.orderOriginCode) {
                    if (this.getMomentFromDateAndHour(order.updateDate).isSame(this.getCurrentMoment(), 'day')) {
                        this.revenuesDay += order.totalAmount
                        this.ordersDay++
                    }

                    if (this.getMomentFromDateAndHour(order.updateDate).isSameOrAfter(this.getMoment7Days(), 'day')) {
                        this.revenues7Day += order.totalAmount
                        this.orders7Day++
                    }
                }
            })
            this.basketsDay = this.ordersDay + 4
            this.baskets7Day = this.orders7Day + 14
        }
    },
    watch: {
        orders() {
            this.calculateMetrics()
        },
        orderOriginCode() {
            this.calculateMetrics()
        }
    }
}
</script>

<style scoped>
.metrics-title {
    font-weight: bold;
    color: black;
    font-size: 1.1rem;
}

.metrics-subtitle {
    font-weight: bold;
    color: var(--primary-color);
}

.card-radius {
    border-radius: 20px;
}

.metrics-background {
    background-color: #f5f5f5;
    border-radius: 10px;
    margin: 0;
}

.metrics-icon {
    background-color: var(--primary-color);
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.metrics-info-label {
    font-weight: bold;
    /*padding: 12px;*/
    color: black;
    display: flex;
}

.metrics-info-value {
    background-color: var(--primary-color);
    border-radius: 19px;
    color: white;
    padding: 12px;
    float: right;
    min-width: 65px;
    text-align: center;
}
</style>
