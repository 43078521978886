<template>
    <div class="home">
        <PaidOrders :orders="orders" :orderOriginCode="orderOriginCode" @refresh="getIECOrders" @changeOrderOriginCode="changeOrderOriginCode"></PaidOrders>
        <Metrics :orders="orders" :orderOriginCode="orderOriginCode"></Metrics>
        <audio id="beep" ref="beep" controls hidden>
            <source src="@/assets/sounds/beep_100_0_1.wav" type="audio/wav">
            {{$t('errorSound')}}
        </audio>
    </div>
</template>

<script>
var Basket = require("@/assets/js/Basket");
var Mqtt = require("@/assets/js/MqttService");
import PaidOrders from "@/components/IEC/PaidOrders/PaidOrders";
import Metrics from "@/components/IEC/Mertics/Metrics";
export default {
    name: 'Home',
    components: {Metrics, PaidOrders},
    data: () => ({
        orders: [],
        orderOriginCode: "IEC_SCAN_AND_PAY",
    }),
    created() {
        this.updatePageInfo(this.$route.meta)
        this.getIECOrders()
        Mqtt.subscribeOrdersRefresh(this.onMessageReceivedOrdersRefresh)
    },
    methods:{
        getIECOrders(){
            this.$root.$emit('showLoader');
            Basket.getIECOrders((data) => {
                this.orders = data
                this.orders.sort((a, b) => this.sortDesc(a, b));
                this.$root.$emit('hideLoader');
            })
        },
        changeOrderOriginCode(code){
            this.orderOriginCode = code
        },
        sortDesc(a, b){
            let momentA = this.getMomentFromDateAndHour(a.updateDate)
            let momentB = this.getMomentFromDateAndHour(b.updateDate)
            if(momentA.isAfter(momentB)){
                return -1
            }else if(momentB.isAfter(momentA)){
                return 1
            }
            return 0
        },
        onMessageReceivedOrdersRefresh(message){
            /*this.$refs.beep.play();*/
            console.log(message)
            let json = JSON.parse(message)
            if(json.subState === "NEW"){
                Basket.get(json.basketComplexId, (data) =>{
                    if(this.isNotNull(data)){
                        this.orders.push(data)
                        this.orders.sort((a, b) => this.sortDesc(a, b));
                    }
                })
            }else{
                this.orders = this.orders.map(order => {
                    if(order.complexId === json.basketComplexId){
                        order.subState = json.subState
                    }
                    return order
                })
            }
        }
    }
};
</script>
<style scoped>

</style>
