<template>
    <div :class="getOrderClass">
        <div class="elmt-btn order-date">
            <span>{{ order.updateDate }}</span>
            <v-tooltip v-if="isNotNull(order.suspect)" bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="suspect-blink ms-2">mdi-alert-outline</v-icon>
                </template>
                <div v-html="getSuspectMessage()"></div>
            </v-tooltip>
        </div>
        <div class="elmt-btn show-product">
            <span v-if="orderOriginCode != 'IEC_PAY_AND_DRIVE'">{{ getBasketProductsCount() }}</span>
            <span v-else>{{ order.basketProducts[0].qty }}</span>

            <OrderDetailedTooltip v-if="orderOriginCode != 'IEC_PAY_AND_DRIVE'" :order="order"></OrderDetailedTooltip>

        </div>
        <div class="elmt-btn">
            <span v-if="orderOriginCode == 'IEC_PAY_AND_DRIVE'">{{ formatPrice3(order.basketProducts[0].product.price) }}€</span>
            <span v-else>{{ formatPrice(order.totalAmount) }}€</span>
        </div>
        <div class="elmt-btn">
            <span v-if="orderOriginCode == 'IEC_PAY_AND_DRIVE'">10 minutes <span class="text-caption">(1.51€)</span></span>
            <span v-else>{{ $t('state.' + order.subState) }}</span>
        </div>
        <div v-if="orderOriginCode == 'IEC_SCAN_AND_PAY'" class="elmt-btn">
            <v-btn :class="getButtonClass(order) + ' ms-4'" :disabled="order.subState !== 'NEW'" color="white" elevation="5" @click="orderValidated">
                <v-icon color="#46b8a6" large>mdi-check</v-icon>
            </v-btn>
            <v-btn :class="getButtonClass(order) + ' ms-4'" :disabled="order.subState !== 'NEW'" color="white" elevation="5" @click="orderCancel">
                <v-icon color="red darken-2" large>mdi-close</v-icon>
            </v-btn>
            <v-btn :class="getButtonClass(order) + ' ms-4'" :disabled="order.subState !== 'NEW'" color="white" elevation="5" @click="$router.push({ path: 'check', query: { id: order.complexId } })">
                <v-icon color="grey darken-3" large>mdi-barcode-scan</v-icon>
            </v-btn>
        </div>
        <div v-else-if="orderOriginCode == 'IEC_CLICK_AND_COLLECT'" class="elmt-btn">
            <v-btn v-if="order.subState === 'NEW'" class="me-4 button-radius" color="primary" elevation="5" width="103px" @click="orderInPreperation">{{ $t('flow.prepare') }}</v-btn>
            <v-btn v-if="order.subState === 'IN_PREPARATION' || order.subState === 'PREPARED'" :disabled="order.subState === 'PREPARED'" class="me-4 button-radius" color="primary" elevation="5" width="103px" @click="showLockerDialog = true">
                {{ $t('flow.locker') }}
            </v-btn>
            <v-btn v-if="order.subState !== 'CANCELED' && order.subState !== 'FINISH'" class="button-radius" color="error" elevation="5" outlined width="103px" @click="orderCancel">
                {{ $t('flow.cancel') }}
            </v-btn>
        </div>
        <div v-else-if="orderOriginCode == 'IEC_EAT_AND_PAY'" class="elmt-btn">
            <v-btn v-if="order.subState === 'NEW'" class="me-4 button-radius" color="primary" elevation="5" width="103px" @click="orderInPreperation">{{ $t('flow.prepare') }}</v-btn>
            <v-btn v-if="order.subState === 'IN_PREPARATION'" class="me-4 button-radius" color="primary" elevation="5" width="103px" @click="orderServed">{{ $t('flow.serve') }}</v-btn>
            <v-btn v-if="order.subState !== 'CANCELED' && order.subState !== 'FINISH' && order.subState !== 'SERVED'" class="button-radius" color="error" elevation="5" outlined width="103px" @click="orderCancel">
                {{ $t('flow.cancel') }}
            </v-btn>
        </div>
        <div v-else-if="orderOriginCode == 'IEC_PAY_AND_DRIVE'" class="elmt-btn">
            <span>{{ formatPrice(order.totalAmount + 1.51) }}€</span>
        </div>

        <v-dialog
            v-model="showLockerDialog"
            max-width="500"
            scrollable
            @click:outside="closeLockerDialog"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ $t('flow.lockerModalTitle') }}
                    <v-spacer></v-spacer>
                    <v-icon @click="closeLockerDialog">mdi-close</v-icon>
                </v-card-title>

                <v-card-text class="pt-2 pb-6" style="max-height: 500px">
                    <v-form
                        ref="lockerForm"
                        v-model="lockerFormValid"
                        lazy-validation
                    >
                        <v-text-field
                            v-model="lockerLocation"
                            :disabled="lockerOpen"
                            :label="$t('flow.lockerLocation')"
                            :rules="[rules.notEmpty]"
                            dense
                            outlined
                        ></v-text-field>

                        <v-text-field
                            v-model="lockerID"
                            :disabled="lockerOpen"
                            :label="$t('flow.lockerNumber')"
                            :rules="[rules.notEmpty]"
                            dense
                            outlined
                            type="number"
                        ></v-text-field>
                    </v-form>

                    <v-divider class="primary"></v-divider>

                    <span class="text-h5 black--text font-weight-medium d-block mt-4 mb-2">{{ $t('flow.productListDialogTitle') }}</span>

                    <div v-for="basketProduct in order.basketProducts" :key="basketProduct.product.id" class="product mt-2">
                        <div class="product-infos">
                            <v-avatar size="70">
                                <img :src="getImageUrl(basketProduct.product)">
                            </v-avatar>
                            <div class="product-details">
                                <span v-if="$i18n.locale === 'fr'" class="product-name">{{ basketProduct.product.name.fr }}</span>
                                <span v-else class="product-name">{{ basketProduct.product.name.en }}</span>
                                <div class="product-ean-price mt-1">
                                    <span class="product-ean">EAN : {{ basketProduct.product.ean }}</span>
                                    <span class="product-price">{{ formatPrice(basketProduct.product.price) }}€</span>
                                </div>
                            </div>
                        </div>
                        <span class="product-qty mx-5">{{ basketProduct.qty }}</span>
                    </div>

                </v-card-text>

                <v-card-actions v-if="!lockerOpen">
                    <v-btn
                        block
                        class="mb-4"
                        color="primary"
                        rounded
                        @click="openLocker"
                    >
                        {{ $t('flow.openLocker') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import moment from "moment";

var Basket = require("@/assets/js/Basket");
var Mqtt = require("@/assets/js/MqttService");
import {config} from '@/assets/js/Utils.js';
import OrderDetailedTooltip from "@/components/IEC/OrderDetailedTooltip";

export default {
    name: 'TableOrdersElement',
    components: {OrderDetailedTooltip},
    props: ['order', 'orderOriginCode'],
    data: (vm) => ({
        fakeStatus: "",
        fakeStatusCode: "INITIALISED",
        showLockerDialog: false,
        showProductListDialog: false,
        lockerLocation: "RTS23",
        lockerID: "1",
        lockerFormValid: true,
        lockerOpen: false,
        rules: {
            notEmpty: value => {
                if (vm.isNull(value)) {
                    return 'Ce champ est requis'
                }
                return true
            },
        }
    }),
    created() {
        this.fakeStatus = this.$t('paidOrders.statusInitialised')
        if (this.isNull(this.order.subState)) {
            this.order.subState = "NEW"
        }
    },
    computed: {
        getOrderClass() {
            let classe = "table-elmt mt-2 "
            if (this.order.subState === "NEW") {
                if (this.isNotNull(this.order.suspect)) {
                    classe += "suspect-order "
                }
            } else {
                if (this.order.subState === "SERVED" || this.order.subState === "FINISH") {
                    classe += "order-finished "
                } else if (this.order.subState === "CANCELED") {
                    classe += "order-canceled "
                }
            }

            return classe
        },
    },
    methods: {
        changeFakeState(code) {
            this.fakeStatusCode = code
            switch (code) {
                case "VALIDATED":
                    this.fakeStatus = this.$t('paidOrders.statusValidated');
                    break;
                case "READY":
                    this.fakeStatus = this.$t('paidOrders.statusReady');
                    break;
                case "REFUSED":
                    this.fakeStatus = this.$t('paidOrders.statusRefused');
                    break;
                case "PREPARED":
                    this.fakeStatus = this.$t('paidOrders.statusPrepared');
                    break;
                case "IN_PROGRESS":
                    this.fakeStatus = this.$t('paidOrders.statusInProgress');
                    break;
            }
        },
        getRandomEvolution() {
            let evolution = [];
            evolution.push(
                this.$t('paidOrders.evolutionInWaiting'),
                this.$t('paidOrders.evolutionInProgress'),
                this.$t('paidOrders.evolutionFinish')
            )

            let randomNumber = Math.floor(Math.random() * evolution.length);
            return evolution[randomNumber];
        },
        getButtonClass(order) {
            if (this.isNotNull(order.suspect)) {
                return "suspect-btn"
            } else {
                return ""
            }
        },
        getBasketProductsCount() {
            let count = 0
            this.order.basketProducts.forEach(product => count += product.qty)
            return count
        },
        orderInPreperation() {
            Basket.changeSubState(this.order.complexId, "IN_PREPARATION", (data) => {
                if (!data.error) {
                    this.order.subState = "IN_PREPARATION"

                    let message = {
                        basketComplexId: this.order.complexId,
                        subState: this.order.subState
                    }
                    Mqtt.publishMessage(Mqtt.topic.ordersRefresh, JSON.stringify(message))
                }
            })
        },
        orderServed() {
            Basket.changeSubState(this.order.complexId, "SERVED", (data) => {
                if (!data.error) {
                    this.order.subState = "SERVED"

                    let message = {
                        basketComplexId: this.order.complexId,
                        subState: this.order.subState
                    }
                    Mqtt.publishMessage(Mqtt.topic.ordersRefresh, JSON.stringify(message))
                }
            })
        },
        orderCancel() {
            Basket.changeSubState(this.order.complexId, "CANCELED", (data) => {
                if (!data.error) {
                    this.order.subState = "CANCELED"

                    let message = {
                        basketComplexId: this.order.complexId,
                        subState: this.order.subState
                    }
                    Mqtt.publishMessage(Mqtt.topic.ordersRefresh, JSON.stringify(message))
                }
            })
        },
        orderValidated() {
            Basket.changeSubState(this.order.complexId, "VALIDATED", (data) => {
                if (!data.error) {
                    this.order.subState = "VALIDATED"

                    let message = {
                        basketComplexId: this.order.complexId,
                        subState: this.order.subState
                    }
                    Mqtt.publishMessage(Mqtt.topic.ordersRefresh, JSON.stringify(message))
                }
            })
        },
        orderRefused() {
            Basket.changeSubState(this.order.complexId, "REFUSED", (data) => {
                if (!data.error) {
                    this.order.subState = "REFUSED"

                    let message = {
                        basketComplexId: this.order.complexId,
                        subState: this.order.subState
                    }
                    Mqtt.publishMessage(Mqtt.topic.ordersRefresh, JSON.stringify(message))
                }
            })
        },
        closeLockerDialog() {
            Mqtt.unsubscribeLocker(this.lockerLocation, this.lockerID)
            this.showLockerDialog = false
            this.lockerLocation = null
            this.lockerID = null
            this.lockerFormValid = true
            this.validProductList()
        },
        openLocker() {
            if (this.$refs.lockerForm.validate()) {
                Mqtt.publishMessage(Mqtt.topic.openLocker.replace("{lockerLocation}", this.lockerLocation).replace("{lockerID}", this.lockerID))
                this.lockerOpen = true;
            }
        },
        validProductList() {
            Basket.changeSubState(this.order.complexId, "PREPARED", (data) => {
                if (!data.error) {
                    this.order.subState = "PREPARED"

                    let message = {
                        basketComplexId: this.order.complexId,
                        subState: this.order.subState
                    }
                    Mqtt.publishMessage(Mqtt.topic.ordersRefresh, JSON.stringify(message))
                }
            })
        },
        getImageUrl(product) {
            return config.WS_URL + '/catalog/product/image/' + product.image
        },
        getSuspectMessage() {
            let message = ""

            let jsonSuspect = JSON.parse(this.order.suspect)

            Object.keys(jsonSuspect).forEach(key => {
                let values = jsonSuspect[key]
                if (key === "PURCHASE_DURATION") {
                    message += this.$t("suspectReason." + key, {val: parseInt(values.value), configuredVal: values.configuredValue}) + "<br>"
                } else {
                    message += this.$t("suspectReason." + key, {val: values.value, configuredVal: values.configuredValue}) + "<br>"
                }
            })

            return message
        }
    },
    watch: {
        order() {
            this.fakeStatus = this.$t('paidOrders.statusInitialised')
            if (this.isNull(this.order.subState)) {
                this.order.subState = "NEW"
            }
        },
        lockerOpen() {
            if (this.lockerOpen) {
                console.log("TEST CMK")
                Mqtt.subscribeLocker(this.lockerLocation, this.lockerID, (message) => {
                    if (this.isNotNull(message) && message == "CLOSED") {
                        this.closeLockerDialog()
                    }
                })
            }
        }
    }
};
</script>

<style scoped>

.show-product {
    display: flex;
    justify-content: center;
    align-items: center;
}

.suspect-order {
    background-color: #ff00002e !important;
    border-color: #f5b0b0;
}

.suspect-btn {
    background-color: #FFFFFF00 !important;
}

.order-finished {
    background-color: #46b8a630 !important;
}

.order-canceled {
    background-color: #efefef !important;
    border: solid 1px #d5d4d4;
    color: gray;
}
</style>
