<template>
    <div class="paid-orders">
        <span class="title">{{ $t('paidOrders.title') }}</span>

        <div class="order-origins">
            <v-icon class="me-4" @click="refresh">mdi-refresh</v-icon>
            <v-btn
                :outlined="orderOriginCode != 'IEC_SCAN_AND_PAY'"
                class="select-order-origin me-4"
                color="primary"
                dark
                elevation="0"
                @click="changeOrderOrigin('IEC_SCAN_AND_PAY')"
            >
                {{ $t('orderOrigin1') }}
            </v-btn>
            <v-btn
                v-if="storeId === 0 || storeId === 1"
                :outlined="orderOriginCode != 'IEC_EAT_AND_PAY'"
                class="select-order-origin me-4"
                color="primary"
                dark
                elevation="0"
                @click="changeOrderOrigin('IEC_EAT_AND_PAY')"
            >
                <v-badge :content="getOrdersToPrepareCountForOrigin.IEC_EAT_AND_PAY" class="custom-badge" dark inline>
                    {{ $t('orderOrigin2') }}
                </v-badge>
            </v-btn>
            <v-btn
                v-if="storeId === 0 || storeId === 1"
                :outlined="orderOriginCode != 'IEC_CLICK_AND_COLLECT'"
                class="select-order-origin me-4"
                color="primary"
                dark
                elevation="0"
                @click="changeOrderOrigin('IEC_CLICK_AND_COLLECT')"
            >
                <v-badge :content="getOrdersToPrepareCountForOrigin.IEC_CLICK_AND_COLLECT" class="custom-badge" dark inline>
                    {{ $t('orderOrigin3') }}
                </v-badge>
            </v-btn>
            <v-btn
                v-if="storeId === 1"
                :outlined="orderOriginCode != 'IEC_PAY_AND_DRIVE'"
                class="select-order-origin"
                color="primary"
                dark
                elevation="0"
                @click="changeOrderOrigin('IEC_PAY_AND_DRIVE')"
            >
                {{ $t('orderOrigin4') }}
            </v-btn>
        </div>

        <br>

        <v-menu
            v-model="pickerDate"
            :close-on-content-click="false"
            min-width="auto"
            offset-y
            transition="scale-transition"
        >
            <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="d-inline-flex">
                    <v-icon>mdi-calendar</v-icon>
                    <span class="ms-1 text-overline">{{ ordersDateFormat }}</span>
                </div>
            </template>
            <v-date-picker
                v-model="ordersDate"
                :events="ordersFromDate"
                :locale="$i18n.locale"
                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                @input="pickerDate = false"
            ></v-date-picker>
        </v-menu>

        <div class="table-wrapper">
            <div v-if="orderOriginCode != 'IEC_PAY_AND_DRIVE'" class="table-header">
                <span>{{ $t('paidOrders.tableHeader1') }}</span>
                <span>{{ $t('paidOrders.tableHeader2') }}</span>
                <span>{{ $t('paidOrders.tableHeader3') }}</span>
                <span>{{ $t('paidOrders.tableHeader4') }}</span>
                <span v-if="orderOriginCode == 'IEC_SCAN_AND_PAY'">{{ $t('paidOrders.tableHeader5') }}</span>
                <span v-else>{{ $t('paidOrders.tableHeader5Bis') }}</span>
            </div>
            <div v-else class="table-header">
                <span>{{ $t('paidOrders.tableHeader1') }}</span>
                <span>{{ $t('paidOrders.tableHeader2Bis') }}</span>
                <span>{{ $t('paidOrders.tableHeader3Bis') }}</span>
                <span>{{ $t('paidOrders.tableHeader4Bis') }}</span>
                <span>{{ $t('paidOrders.tableHeader5Ter') }}</span>
            </div>
            <div class="horizontal-divider"></div>
            <div class="table-body">
                <div class="table-elmt-wrapper">
                    <TableOrdersElement v-for="order in getOrdersToShow" :key="order.id" :order="order" :orderOriginCode="orderOriginCode"></TableOrdersElement>
                </div>
            </div>
        </div>

        <div class="table-footer">
            <div class="elmt-per-page">
                <span>{{ $t('paidOrders.elementsPerPage') }}</span>

                <v-btn class="ms-10" color="white" fab x-small @click="lessOrdersPerPage">
                    <span class="elmt-per-page-action">-</span>
                </v-btn>

                <span id="elmt-per-page-data" class="mx-4">{{ ordersPerPage }}</span>

                <v-btn color="white" fab x-small @click="moreOrdersPerPage">
                    <span class="elmt-per-page-action">+</span>
                </v-btn>
            </div>

            <div v-if="ordersFromOriginToday.length > ordersPerPage" class="page-selector">
                <v-icon ref="previous-orders" :disabled="!havePreviousPage" @click="page--">mdi-arrow-left</v-icon>
                <span ref="page" class="mx-2">{{ page }}</span>
                <v-icon ref="next-orders" :disabled="!haveNextPage" @click="page++">mdi-arrow-right</v-icon>
            </div>
        </div>
    </div>
</template>

<script>
import TableOrdersElement from "@/components/IEC/PaidOrders/TableOrdersElement";
import moment from "moment";

export default {
    name: 'PaidOrders',
    props: ['orders', 'orderOriginCode'],
    components: {TableOrdersElement},
    data: () => ({
        ordersDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        pickerDate: false,
        ordersPerPage: 10,
        page: 1,
        orderOrigin: "",
        ordersFromOrigin: [],
        ordersFromOriginToday: [],
        storeId: JSON.parse(localStorage.getItem('IEC_BO_store')).id
    }),
    created() {
        this.orderOrigin = this.$t('orderOrigin1')

        this.$root.$on('changeStore', () => {
            this.storeId = JSON.parse(localStorage.getItem('IEC_BO_store')).id
        });
    },
    computed: {
        getOrdersToShow() {
            this.ordersFromOrigin = this.orders.filter(order => order.orderOrigin === this.orderOriginCode)

            this.ordersFromOriginToday = this.ordersFromOrigin.filter(order => {
                if (this.getMomentFromDateAndHour(order.updateDate).isSame(moment(this.ordersDate), "day")) {
                    return true
                }
                return false
            })

            let first = this.ordersPerPage * (this.page - 1)
            let last = this.ordersPerPage * this.page

            if (last > this.ordersFromOriginToday.length) {
                last = this.ordersFromOriginToday.length
            }

            return this.ordersFromOriginToday.slice(first, last)
        },
        getOrdersToPrepareCountForOrigin() {
            let toPrepare = {
                'IEC_CLICK_AND_COLLECT': "0",
                'IEC_EAT_AND_PAY': "0"
            }

            toPrepare.IEC_CLICK_AND_COLLECT = this.orders.filter(order => {
                if (order.orderOrigin === 'IEC_CLICK_AND_COLLECT' && order.subState !== "SERVED" && order.subState !== "FINISH" && order.subState !== "CANCELED" && this.getMomentFromDateAndHour(order.updateDate).isSame(moment(this.ordersDate), "day")) {
                    return true
                }
                return false
            }).length.toString()

            toPrepare.IEC_EAT_AND_PAY = this.orders.filter(order => {
                if (order.orderOrigin === 'IEC_EAT_AND_PAY' && order.subState !== "SERVED" && order.subState !== "FINISH" && order.subState !== "CANCELED" && this.getMomentFromDateAndHour(order.updateDate).isSame(moment(this.ordersDate), "day")) {
                    return true
                }
                return false
            }).length.toString()

            return toPrepare
        },
        ordersDateFormat() {
            return this.getDateFrFromDateEn(this.ordersDate)
        },
        haveNextPage() {
            if (this.ordersFromOriginToday.length / this.ordersPerPage > this.page) {
                return true
            }
            return false
        },
        havePreviousPage() {
            if (this.page <= 1) {
                return false
            }
            return true
        },
    },
    methods: {
        refresh() {
            this.$emit('refresh')
        },
        moreOrdersPerPage() {
            this.ordersPerPage++;
        },
        lessOrdersPerPage() {
            if (this.ordersPerPage > 1) {
                this.ordersPerPage--;
            }
        },
        changeOrderOrigin(code) {
            this.$emit("changeOrderOriginCode", code)
            switch (code) {
                case "IEC_SCAN_AND_PAY":
                    this.orderOrigin = this.$t('orderOrigin1');
                    break;
                case "IEC_EAT_AND_PAY":
                    this.orderOrigin = this.$t('orderOrigin2');
                    break;
                case "IEC_CLICK_AND_COLLECT":
                    this.orderOrigin = this.$t('orderOrigin3');
                    break;
                case "IEC_PAY_AND_DRIVE":
                    this.orderOrigin = this.$t('orderOrigin4');
                    break;
            }
        },
        ordersFromDate(date) {
            let orders = this.ordersFromOrigin.filter(order => this.getMomentFromDateAndHour(order.updateDate).isSame(this.getMomentFromDateEn(date), "day"))

            if (orders.length > 0) {
                return "custom-date-picker-event grey lighten-1"
            }
            return false
        }
    },
    watch: {
        orderOriginCode() {
            this.page = 1
        },
        ordersDate() {
            this.page = 1
        }
    }
};
</script>

<style>
.v-btn:not(.v-btn--outlined) .custom-badge .v-badge__badge {
    background-color: white !important;
    color: var(--primary-color) !important;
}

.custom-date-picker-event {
    height: 6px !important;
    width: 6px !important;
}
</style>
