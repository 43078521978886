<template>
    <v-menu
        v-model="show"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
    >
        <template v-slot:activator="{ }">
            <v-icon class="ms-4" color="grey darken-3" @click="show = !show">mdi-eye-outline</v-icon>
        </template>

        <v-card class="order-details pa-5" elevation="7" width="350">
            <span class="order-title">{{ order.updateDate }}</span>

            <div v-for="basketProduct in order.basketProducts" :key="basketProduct.product.id" class="product">
                <div class="product-infos">
                    <v-avatar size="70">
                        <img :src="getImageUrl(basketProduct.product)">
                    </v-avatar>
                    <div class="product-details">
                        <span v-if="$i18n.locale === 'fr'" class="product-name">{{ basketProduct.product.name.fr }}</span>
                        <span v-else class="product-name">{{ basketProduct.product.name.en }}</span>
                        <div class="product-nutriscore-price mt-1">
                            <v-img :src="basketProduct.product.nutriscore" height="27" width="50"/>
                            <span class="product-price">{{ formatPrice(basketProduct.product.price) }}€</span>
                        </div>
                    </div>
                </div>
                <span class="product-qty mx-5">{{ basketProduct.qty }}</span>
            </div>

            <div class="order-footer mt-4 pt-1">
                <span class="order-total">TOTAL :</span>
                <span class="order-amount">{{ formatPrice(order.totalAmount) }}€</span>
            </div>

        </v-card>
    </v-menu>
</template>

<script>
import {config} from '@/assets/js/Utils.js';

var OpenFoodFacts = require("@/assets/js/OpenFoodFacts");

export default {
    name: 'OrderDetailedTooltip',
    props: ['order'],
    data: () => ({
        show: false,
    }),
    created() {
        this.order.basketProducts.forEach(basketProduct => {
            this.getOpenFoodFactsData(basketProduct.product.ean).then(image => {
                basketProduct.product.nutriscore = image
            })
        })
    },
    methods: {
        getImageUrl(product) {
            return config.WS_URL + '/catalog/product/image/' + product.image
        },
        async getOpenFoodFactsData(ean) {
            let response = await OpenFoodFacts.get(ean)
            if (response.status === 200 && this.isNotNull(response.data) && this.isNotNull(response.data.product) && this.isNotNull(response.data.product.nutriscore_grade)) {
                return require("@/assets/img/nutriscore_" + response.data.product.nutriscore_grade + ".png")
            }
        }
    },
    watch: {}
};
</script>

<style scoped>
</style>
